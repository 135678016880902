define("discourse/plugins/discourse-chat-integration/admin/routes/admin-plugins-chat-integration", ["exports", "discourse/routes/discourse", "@ember/object"], function (_exports, _discourse, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend(dt7948.p({
    model() {
      return this.store.findAll("provider");
    },
    showSettings() {
      this.transitionTo("adminSiteSettingsCategory", "plugins", {
        queryParams: {
          filter: "chat_integration"
        }
      });
    }
  }, [["method", "showSettings", [_object.action]]]));
});