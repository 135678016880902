define("discourse/plugins/discourse-chat-integration/admin/controllers/modals/admin-plugins-chat-integration-edit-rule", ["exports", "@ember/controller", "discourse/mixins/modal-functionality", "discourse/lib/ajax-error", "discourse-common/utils/decorators", "@ember/runloop"], function (_exports, _controller, _modalFunctionality, _ajaxError, _decorators, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(_modalFunctionality.default, dt7948.p({
    saveDisabled: false,
    setupKeydown() {
      (0, _runloop.schedule)("afterRender", () => {
        $("#chat-integration-edit-channel-modal").keydown(e => {
          if (e.keyCode === 13) {
            this.send("save");
          }
        });
      });
    },
    showCategory(type) {
      return type === "normal";
    },
    actions: {
      save(rule) {
        if (this.get("saveDisabled")) {
          return;
        }
        rule.save().then(() => this.send("closeModal")).catch(_ajaxError.popupAjaxError);
      }
    }
  }, [["method", "setupKeydown", [(0, _decorators.on)("init")]], ["method", "showCategory", [(0, _decorators.default)("model.rule.type")]]]));
});