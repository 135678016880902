define("discourse/plugins/discourse-chat-integration/admin/controllers/modals/admin-plugins-chat-integration-test", ["exports", "@ember/controller", "I18n", "discourse/mixins/modal-functionality", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/utils/decorators", "@ember/runloop"], function (_exports, _controller, _I18n, _modalFunctionality, _ajax, _ajaxError, _decorators, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(_modalFunctionality.default, dt7948.p({
    setupKeydown() {
      (0, _runloop.schedule)("afterRender", () => {
        $("#chat_integration_test_modal").keydown(e => {
          if (e.keyCode === 13) {
            this.send("send");
          }
        });
      });
    },
    sendDisabled(topicId) {
      return !topicId;
    },
    actions: {
      send() {
        if (this.get("sendDisabled")) {
          return;
        }
        this.set("loading", true);
        (0, _ajax.ajax)("/admin/plugins/chat-integration/test", {
          data: {
            channel_id: this.get("model.channel.id"),
            topic_id: this.get("model.topic_id")
          },
          type: "POST"
        }).then(() => {
          this.set("loading", false);
          this.flash(_I18n.default.t("chat_integration.test_modal.success"), "success");
        }).catch(_ajaxError.popupAjaxError);
      }
    }
  }, [["method", "setupKeydown", [(0, _decorators.on)("init")]], ["method", "sendDisabled", [(0, _decorators.default)("model.topic_id")]]]));
});