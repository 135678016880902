define("discourse/plugins/discourse-chat-integration/admin/components/rule-row", ["exports", "@ember/component", "discourse/lib/ajax-error", "discourse-common/utils/decorators"], function (_exports, _component, _ajaxError, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "tr",
    isCategory(type) {
      return type === "normal";
    },
    isMessage(type) {
      return type === "group_message";
    },
    isMention(type) {
      return type === "group_mention";
    },
    actions: {
      delete(rule) {
        rule.destroyRecord().then(() => this.refresh()).catch(_ajaxError.popupAjaxError);
      }
    }
  }, [["method", "isCategory", [(0, _decorators.default)("rule.type")]], ["method", "isMessage", [(0, _decorators.default)("rule.type")]], ["method", "isMention", [(0, _decorators.default)("rule.type")]]]));
});