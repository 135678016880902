define("discourse/plugins/discourse-chat-integration/admin/models/rule", ["exports", "I18n", "discourse/models/rest", "discourse/models/category", "discourse-common/utils/decorators"], function (_exports, _I18n, _rest, _category, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _rest.default.extend(dt7948.p({
    available_filters(provider) {
      const available = [];
      if (provider === "slack") {
        available.push({
          id: "thread",
          name: _I18n.default.t("chat_integration.filter.thread"),
          icon: "chevron-right"
        });
      }
      available.push({
        id: "watch",
        name: _I18n.default.t("chat_integration.filter.watch"),
        icon: "exclamation-circle"
      }, {
        id: "follow",
        name: _I18n.default.t("chat_integration.filter.follow"),
        icon: "circle"
      }, {
        id: "mute",
        name: _I18n.default.t("chat_integration.filter.mute"),
        icon: "times-circle"
      });
      return available;
    },
    available_types: [{
      id: "normal",
      name: _I18n.default.t("chat_integration.type.normal")
    }, {
      id: "group_message",
      name: _I18n.default.t("chat_integration.type.group_message")
    }, {
      id: "group_mention",
      name: _I18n.default.t("chat_integration.type.group_mention")
    }],
    category_id: null,
    tags: null,
    channel_id: null,
    filter: "watch",
    type: "normal",
    error_key: null,
    removeUnneededInfo() {
      const type = this.get("type");
      if (type === "normal") {
        this.set("group_id", null);
      } else {
        this.set("category_id", null);
      }
    },
    category(categoryId) {
      if (categoryId) {
        return _category.default.findById(categoryId);
      } else {
        return false;
      }
    },
    filterName(filter) {
      return _I18n.default.t(`chat_integration.filter.${filter}`);
    },
    updateProperties() {
      return this.getProperties(["type", "category_id", "group_id", "tags", "filter"]);
    },
    createProperties() {
      return this.getProperties(["type", "channel_id", "category_id", "group_id", "tags", "filter"]);
    }
  }, [["method", "available_filters", [(0, _decorators.default)("channel.provider")]], ["method", "removeUnneededInfo", [(0, _decorators.observes)("type")]], ["method", "category", [(0, _decorators.default)("category_id")]], ["method", "filterName", [(0, _decorators.default)("filter")]]]));
});