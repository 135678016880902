define("discourse/plugins/discourse-chat-integration/admin/controllers/admin-plugins-chat-integration-provider", ["exports", "@ember/controller", "discourse/lib/show-modal", "discourse-common/utils/decorators"], function (_exports, _controller, _showModal, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    modalShowing: false,
    anyErrors(channels) {
      let anyErrors = false;
      channels.forEach(channel => {
        if (channel.error_key) {
          anyErrors = true;
        }
      });
      return anyErrors;
    },
    actions: {
      createChannel() {
        this.set("modalShowing", true);
        const model = {
          channel: this.store.createRecord("channel", {
            provider: this.get("model.provider.id"),
            data: {}
          }),
          provider: this.get("model.provider")
        };
        (0, _showModal.default)("admin-plugins-chat-integration-edit-channel", {
          model,
          admin: true
        });
      },
      editChannel(channel) {
        this.set("modalShowing", true);
        const model = {
          channel,
          provider: this.get("model.provider")
        };
        (0, _showModal.default)("admin-plugins-chat-integration-edit-channel", {
          model,
          admin: true
        });
      },
      testChannel(channel) {
        this.set("modalShowing", true);
        (0, _showModal.default)("admin-plugins-chat-integration-test", {
          model: {
            channel
          },
          admin: true
        });
      },
      createRule(channel) {
        this.set("modalShowing", true);
        const model = {
          rule: this.store.createRecord("rule", {
            channel_id: channel.id,
            channel
          }),
          channel,
          provider: this.get("model.provider"),
          groups: this.get("model.groups")
        };
        (0, _showModal.default)("admin-plugins-chat-integration-edit-rule", {
          model,
          admin: true
        });
      },
      editRuleWithChannel(rule, channel) {
        this.set("modalShowing", true);
        const model = {
          rule,
          channel,
          provider: this.get("model.provider"),
          groups: this.get("model.groups")
        };
        (0, _showModal.default)("admin-plugins-chat-integration-edit-rule", {
          model,
          admin: true
        });
      },
      showError(channel) {
        this.set("modalShowing", true);
        (0, _showModal.default)("admin-plugins-chat-integration-channel-error", {
          model: channel,
          admin: true
        });
      }
    }
  }, [["method", "anyErrors", [(0, _decorators.default)("model.channels")]]]));
});